<template>
  <div class="bigData">
    <TopNavBar />
    <Carousel :carousels="carousels" />
    <div class="bigData_box">
      <div class="bigDatas">
        <div v-for="(item, index) in bigDatas" :key="index" class="item">
          <div class="img_content" @click="onPlay(item)">
            <img :src="item.images" alt="" />
            <div>
              <img src="@/assets/images/bigData/icon_play.png" alt="" />
            </div>
          </div>
          <div class="name">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <Footer />
    <el-dialog :visible.sync="isShow" width="78vw" :before-close="handleClose">
      <video
        ishivideo="true"
        autoplay="true"
        isrotate="true"
        autoHide="true"
        controls="controls"
        class="videoa"
        v-if="isShow"
      >
        <source :src="mp4Data" type="video/mp4" />
      </video>
    </el-dialog>
  </div>
</template>

<script>
import { bigDataList } from '../api/index'
export default {
  name: "BigData",
  data() {
    return {
      bigDatas:null,
      isShow: false,
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/5.png"),
        },
      ],
      mp4Data: null
    };
  },
  created(){
    this.loadBigDataList()
  },
  methods: {
    async loadBigDataList() {
      const res = await bigDataList()
      this.bigDatas = res
      this.bigDatas.forEach(item => {
        item.images = process.env.VUE_APP_API_URL+item.images
      });
    },
    onPlay(item) {
      this.mp4Data = item.video
      this.isShow = true;
    },
    handleClose() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.bigData {
  .bigData_box {
    margin-top: 80px;
    padding: 0 75px;
    .bigDatas {
      margin-top: 70px;
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(auto-fill, minmax(540px, 1fr));
      .item {
        margin-bottom: 60px;
        .img_content {
          position: relative;
          cursor: pointer;
          > img {
            width: 540px;
            height: 304px;
            display: block;
          }
          > div {
            background-color: #00000033;
            position: absolute;
            top: 0;
            width: 540px;
            height: 100%;
            > img {
              width: 34px;
              height: 34px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .name {
          line-height: 80px;
          font-size: 25px;
          color: #000;
          box-shadow: 0px 0px 5px #eeeeee;
        }
      }
    }
  }
  ::v-deep .el-dialog {
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .el-dialog__body {
      padding: 30px 0 0;
      position: relative;
      .videoa {
        width: 100%;
      }
    }
  }
}
</style>
